<template>
  <div class="editor">
    <vue-editor v-model="content"></vue-editor>

    <b-button outlinedNO rounded type="is-info" @click="post">
      <!-- 
        <i class="fal fa-plus" style=""></i> 
         -->
      <span> Post</span>
    </b-button>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
  components: {
    VueEditor,
  },

  data() {
    return {
      content: "<h1>Some initial content</h1><p></p><p></p><p></p>",
    };
  },
  methods: {
    post() {
      //   const response = await fetch("/api/bots");
      const data = window.API.me.getUserBotsOwner();
    },
  },
};
</script>
